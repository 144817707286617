import React, { useEffect, useState } from "react"
import * as Styles from "./index.module.scss"
import { css } from "@jsUtils"
import Button from "../../components/button"
import { useT } from "@hooks"
import { Cross } from "@dom"

const Locked = (props) => {
  const t = useT("predictive-gaming")

  const { active, setBackground, nextGame } = props

  useEffect(() => {
    if (active) setBackground("white")
  }, [active])
  return (
    <div className={Styles.component}>
      <div className={Styles.wrapper}>
        <div className={Styles.headline}>
          <Cross position={"top left"} />
          <Cross position={"top right"} />
          <Cross position={"bottom left"} />
          <Cross position={"bottom right"} />

          <h1 className={Styles.content}>{t("locked.headline")}</h1>
          <p className={Styles.content}>{t("locked.subhead")}</p>
        </div>

        <div className={Styles.copy}>
          <p>{t("locked.nextGames")}</p>
          <h3>{nextGame}</h3>
          {/* <p>{t("locked.copy")}</p> */}
        </div>
        {/* <Button title={t("locked.cta")} onClick={onStart} /> */}
      </div>
    </div>
  )
}

export default Locked
