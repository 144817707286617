// extracted by mini-css-extract-plugin
export var component = "index-module--component--2-mDi";
export var background = "index-module--background--3X7BH";
export var content = "index-module--content--3ztPw";
export var player = "index-module--player--1budR";
export var active = "index-module--active--3Pi9u";
export var activePlayerMobile = "index-module--activePlayerMobile--3jkPf";
export var activePlayerDesktop = "index-module--activePlayerDesktop--3uzbP";
export var cross = "index-module--cross--2EISL";
export var image = "index-module--image--2PNex";
export var choicesWrapper = "index-module--choicesWrapper--wpNfu";
export var choices = "index-module--choices--3LYfc";
export var choice = "index-module--choice--TsvS4";