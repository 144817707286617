// extracted by mini-css-extract-plugin
export var component = "index-module--component--3z7JN";
export var content = "index-module--content--YgGBu";
export var title = "index-module--title--1dN2W";
export var terms = "index-module--terms--28fbO";
export var buttons = "index-module--buttons--MfIUe";
export var optin = "index-module--optin--2AqIq";
export var check = "index-module--check--3uER5";
export var active = "index-module--active--1Uld6";
export var form = "index-module--form--1rO0h";
export var error = "index-module--error--3INkp";
export var row = "index-module--row--13ION";
export var input = "index-module--input--wWCeW";