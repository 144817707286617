import React, { useEffect, useState } from "react"
import * as Styles from "./index.module.scss"
import { css } from "@jsUtils"
import Button from "../../components/button"
import { useT } from "@hooks"
import { Cross } from "@dom"

const Intro = (props) => {
  const t = useT("predictive-gaming")

  const { active, setBackground, nextGame, onStart } = props

  useEffect(() => {
    if (active) setBackground("white")
  }, [active])
  return (
    <div className={Styles.component}>
      <div className={Styles.wrapper}>
        <div className={Styles.headline}>
          <Cross position={"top left"} />
          <Cross position={"top right"} />
          <Cross position={"bottom left"} />
          <Cross position={"bottom right"} />

          <h1 className={Styles.content}>{t("intro.headline")}</h1>
          <h2 className={Styles.content}>{t("intro.subhead")}</h2>
        </div>

        <div className={Styles.copy}>
          <p>{t("intro.nextGames")}</p>
          <h3>{nextGame}</h3>
          {/* <p>{t("intro.copy")}</p> */}
        </div>
        <Button title={t("intro.cta")} onClick={onStart} />
      </div>
    </div>
  )
}

export default Intro
