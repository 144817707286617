// extracted by mini-css-extract-plugin
export var component = "index-module--component--1Y8Or";
export var wrapper = "index-module--wrapper--wjSrK";
export var copy = "index-module--copy--ukWpD";
export var headline = "index-module--headline--1B-it";
export var content = "index-module--content--2EX_8";
export var cross = "index-module--cross--S1cqa";
export var top = "index-module--top--3PvvR";
export var right = "index-module--right--DFka_";
export var bottom = "index-module--bottom--BOpoo";
export var left = "index-module--left--3qyV4";