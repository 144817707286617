import React, { useEffect, useState } from "react"
import * as Styles from "./index.module.scss"

import Headline from "../../components/headline"
import Group from "../../components/group"
import { useLanguage } from "@hooks"

const MultipleMultipleChoice = (props) => {
  const { data, onAnswer, active, setBackground, onPrevious } = props
  const isSpanish = useLanguage("es")
  const title = isSpanish ? data.title.headlineEs : data.title.headlineEn
  const copy = isSpanish ? data.title.titleEs : data.title.titleEn

  const [answers, setAnswers] = useState(null)

  const onHandleAnswer = (answer, index) => {
    answers[index] = answer
    onAnswer(data.title.titleEn, answers)
  }

  useEffect(() => {
    if (active) setBackground("black")
  }, [active])

  useEffect(() => {
    let _answers = []
    data.content.map((group) => {
      _answers.push({ title: group.titleEn, answer: "" })
    })

    setAnswers(_answers)
  }, [])

  return (
    <div className={Styles.component}>
      <div className={Styles.content}>
        <Headline title={title} logo={data.title.logo} copy={copy} />
        <div className={Styles.choices}>
          {data.content.map((group, index) => (
            <Group
              key={index}
              index={index}
              group={group}
              className={Styles.group}
              isSpanish={isSpanish}
              onAnswer={onHandleAnswer}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default MultipleMultipleChoice
