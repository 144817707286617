import React, { useContext, useEffect } from "react"
import * as Styles from "./index.module.scss"
import { css } from "@jsUtils"
import Button from "../../components/button"
import { useT } from "@hooks"
import { Cross } from "@dom"
import { SiteContext } from "@context/siteContext"

const Submit = (props) => {
  const t = useT("predictive-gaming")

  const { actions } = useContext(SiteContext)
  const handleClick = (e) => {
    e.preventDefault()
    actions.route("/")
  }

  const { active, setBackground, nextGame } = props
  useEffect(() => {
    if (active) setBackground("white")
  }, [active])

  return (
    <div className={Styles.component}>
      <div className={Styles.wrapper}>
        <div className={Styles.headline}>
          <Cross position={"top left"} />
          <Cross position={"top right"} />
          <Cross position={"bottom left"} />
          <Cross position={"bottom right"} />

          <h1 className={Styles.content}>{t("submit.headline")}</h1>
        </div>

        <div className={Styles.copy}>
          <p>{t("submit.copy").replace("[DATE]", nextGame)}</p>
        </div>
        <Button title={t("submit.cta")} onClick={handleClick} />
      </div>
    </div>
  )
}

export default Submit
