import React from "react"
import * as Styles from "./index.module.scss"
import { useT } from "@hooks"

const Headline = (props) => {
  const t = useT("predictive-gaming")
  const title = () => {
    return props.title
  }

  return (
    <div className={Styles.component}>
      <div className={Styles.previous} onClick={props.onPrevious}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.383"
          height="15.746"
          viewBox="0 0 21.383 15.746"
        >
          <g
            id="Group_900"
            data-name="Group 900"
            transform="translate(1836.884 43.213) rotate(180)"
          >
            <g id="Group_38" data-name="Group 38" transform="translate(1813.5 26.962)">
              <path
                id="Path_24"
                data-name="Path 24"
                d="M817.314,82.442l8.492-7.58-8.313-7.42"
                transform="translate(-803.174 -66.564)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <line
                id="Line_6"
                data-name="Line 6"
                x2="20"
                transform="translate(2 8.378)"
                fill="none"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
              />
            </g>
          </g>
        </svg>
        {t("global.previous")}
      </div>
      <div className={Styles.title}>{title()}</div>
      <h1>{props.copy}</h1>
    </div>
  )
}

export default Headline
