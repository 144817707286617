import React, { useEffect } from "react"
import * as Styles from "./index.module.scss"
import { css } from "@jsUtils"
import Button from "../../components/button"
import { useT, useLanguage } from "@hooks"
import { Cross } from "@dom"

const Terms = (props) => {
  const { onAgree, onReturn, active, setBackground } = props
  const t = useT("predictive-gaming")
  const isSpanish = useLanguage("es")

  useEffect(() => {
    if (active) setBackground("#fff")
  }, [active])
  return (
    <div className={Styles.component}>
      <div className={Styles.content}>
        <div className={Styles.title} dangerouslySetInnerHTML={{ __html: t("terms.headline") }} />
        <div className={Styles.terms}>
          <Cross position={"top left"} theme={"white"} />
          <Cross position={"top right"} theme={"white"} />
          <Cross position={"bottom left"} theme={"white"} />
          <Cross position={"bottom right"} theme={"white"} />
          <div className={Styles.copy}>
            {isSpanish && <iframe width="100%" height="100%" src={"/terms/es.html"} />}
            {!isSpanish && <iframe width="100%" height="100%" src={"/terms/en.html"} />}
          </div>
        </div>
        <div className={Styles.buttons}>
          <Button title={t("global.agree")} onClick={onAgree} theme={"white"} />
          <Button title={t("global.return")} onClick={onReturn} theme={"white"} />
        </div>
      </div>
    </div>
  )
}

export default Terms
