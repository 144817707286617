import React, { useState, useEffect } from "react"
import * as Styles from "./index.module.scss"
import Headline from "../../components/headline"
import Choice from "../../components/choice"
import { useLanguage } from "@hooks"
import { Cross } from "@dom"
import { css } from "@jsUtils"

const MultipleChoice = (props) => {
  const { data, onAnswer, active, setBackground, onPrevious } = props
  const isSpanish = useLanguage("es")
  const title = isSpanish ? data.title.headlineEs : data.title.headlineEn
  const copy = isSpanish ? data.title.titleEs : data.title.titleEn
  const [selected, setSelected] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const selectAnswer = (answer) => {
    let content = data.content.find((q) => q.titleEn == answer)
    let image = content?.image?.mediaItemUrl

    setSelected(answer)
    setSelectedImage(image)
    onAnswer(data.title.titleEn, answer)
  }

  useEffect(() => {
    if (active) setBackground("black")
  }, [active])

  const renderChoice = (choice, index) => {
    return (
      <Choice
        key={index}
        text={isSpanish ? choice.titleEs : choice.titleEn}
        value={choice.titleEn ? choice.titleEn : 0}
        icon={choice.icon}
        selected={selected}
        setSelected={selectAnswer}
        className={Styles.choice}
      />
    )
  }
  return (
    <div className={Styles.component}>
      {selectedImage && (
        <div
          className={Styles.background}
          style={{
            backgroundImage: `url(${selectedImage})`,
          }}
        />
      )}
      {!selectedImage && data.background && (
        <div
          className={Styles.background}
          style={{
            backgroundImage: `url(${data.background})`,
          }}
        />
      )}
      <div className={Styles.content}>
        <Headline copy={copy} title={title} onPrevious={onPrevious}></Headline>

        {/* {selectedImage && (
          <div className={css(Styles.player, selectedImage && Styles.active)}>
            <Cross position={"top left"} theme={"white"} className={Styles.cross} />
            <Cross position={"top right"} theme={"white"} className={Styles.cross} />
            <Cross position={"bottom left"} theme={"white"} className={Styles.cross} />
            <Cross position={"bottom right"} theme={"white"} className={Styles.cross} />
            {selectedImage && (
              <div
                className={Styles.image}
                style={{
                  backgroundImage: `url(${selectedImage})`,
                }}
              />
            )}
          </div>
        )} */}
        <div className={Styles.choicesWrapper}>
          <div className={Styles.choices}>
            {data.content.map((choice, index) => renderChoice(choice, index))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MultipleChoice
