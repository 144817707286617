import React, { useState, useEffect } from "react"
import Choice from "../choice"
import * as Styles from "./index.module.scss"

import { css } from "@jsUtils"

const Group = (props) => {
  const { index, className, group, isSpanish, onAnswer } = props
  const [selected, setSelected] = useState(null)
  const title = isSpanish ? group.title_es : group.title_en

  useEffect(() => {
    if (selected) onAnswer({ title: group.title_en, answer: selected }, index)
  }, [selected])
  return (
    <div className={css(className, Styles.group)}>
      <div className={Styles.title}>{title}</div>
      {group.content.map((choice, index) => (
        <Choice
          text={isSpanish ? choice.title_es : choice.title_en}
          value={choice.title_en}
          icon={choice.icon}
          selected={selected}
          setSelected={setSelected}
          key={index}
        />
      ))}
    </div>
  )
}

export default Group
