import React, { useContext, useEffect } from "react"

import { OverlayContent, Head } from "@dom"

import { SiteContext } from "@context/siteContext"
import { PredictiveGamingContext } from "@context/predictiveGamingContext"

import PredictiveGaming from "@components/predictiveGaming"
import Locked from "@components/predictiveGaming/screens/locked"
import Leaderboard from "@components/predictiveGaming/screens/leaderboard"
export default function Index(data) {
  const { actions, transition } = useContext(SiteContext)
  const { gameData } = useContext(PredictiveGamingContext)

  useEffect(() => {
    if (!transition) actions.setTransition(true)
    setTimeout(() => {
      actions.setReady(true)
    }, 666)
  }, [])
  return (
    <>
      <Head />
      <OverlayContent mode={"predictive-gaming"} />

      {gameData.active && <PredictiveGaming gameData={gameData}></PredictiveGaming>}
      {!gameData.active && (
        <>
          <Locked
            nextGame={gameData.nextGame}
            active={true}
            setBackground={actions.setBackground}
          ></Locked>
          {/* <Leaderboard
            nextGame={gameData.nextGame}
            active={true}
            setBackground={actions.setBackground}
          ></Leaderboard> */}
        </>
      )}
    </>
  )
}
