import React from "react"
import * as Styles from "./index.module.scss"
import { css } from "@jsUtils"

const Choice = (props) => {
  const { icon, value, text, selected, setSelected, className } = props

  if (icon) {
    return (
      <div
        className={css(className, Styles.choice, selected === value && Styles.active)}
        title={text ? text : 0}
        onClick={() => setSelected(value)}
      >
        <span
          style={{
            backgroundImage: `url(${icon})`,
          }}
        ></span>{" "}
        {text ? text : 0}
      </div>
    )
  } else {
    return (
      <div
        className={css(className, Styles.choice, selected === value && Styles.active)}
        title={text ? text : 0}
        onClick={() => setSelected(value)}
      >
        {text ? text : 0}
      </div>
    )
  }
}

export default Choice
