import React, { useRef, useEffect, useState } from "react"
import * as Styles from "./index.module.scss"
import { css } from "@jsUtils"
import Button from "../../components/button"
import { useT } from "@hooks"
import { Cross } from "@dom"

const Form = (props) => {
  const { onSubmit, active, setBackground } = props
  const t = useT("predictive-gaming")
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const emailRef = useRef()
  const checkboxRef = useRef()
  const [error, setError] = useState(null)
  const [optin, setOptin] = useState(false)

  const handleSubmit = () => {
    let _error = null

    if (firstNameRef.current.value === "") _error = { field: "First Name" }
    if (lastNameRef.current.value === "") _error = { field: "Last Name" }
    if (emailRef.current.value === "") _error = { field: "Email" }

    if (_error) {
      setError(_error)
    } else {
      setError(null)
      onSubmit([
        {
          node: "First Name",
          nodeValue: firstNameRef.current.value,
        },
        {
          node: "Last Name",
          nodeValue: lastNameRef.current.value,
        },
        {
          node: "Email",
          nodeValue: emailRef.current.value,
        },
        {
          node: "Opt-in",
          nodeValue: optin,
        },
      ])
    }
  }

  useEffect(() => {
    if (active) setBackground("black")
  }, [active])

  return (
    <div className={Styles.component}>
      <div className={Styles.content}>
        <div className={Styles.title} dangerouslySetInnerHTML={{ __html: t("form.headline") }} />
        <div className={Styles.terms}>
          <Cross position={"top left"} theme={"white"} />
          <Cross position={"top right"} theme={"white"} />
          <Cross position={"bottom left"} theme={"white"} />
          <Cross position={"bottom right"} theme={"white"} />
          <div className={Styles.form}>
            {error && (
              <div className={Styles.error}>
                {t("form.provide")} {error.field}
              </div>
            )}
            <div className={Styles.row}>
              <div className={Styles.input}>
                <label>{t("form.firstName")}</label>
                <input type={"text"} ref={firstNameRef} />
              </div>
              <div className={Styles.input}>
                <label>{t("form.lastName")}</label>
                <input type={"text"} ref={lastNameRef} />
              </div>
            </div>
            <div className={Styles.row}>
              <div className={Styles.input}>
                <label>{t("form.email")}</label>
                <input type={"text"} ref={emailRef} />
              </div>
            </div>
            <div className={Styles.row}>
              <div className={Styles.optin} onClick={() => setOptin(!optin)}>
                <div className={css(Styles.check, optin && Styles.active)}>
                  <input type="checkbox" ref={checkboxRef} />
                </div>
                <span>{t("form.optin")}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.buttons}>
          <Button title={t("global.continue")} onClick={handleSubmit} theme={"white"} />
        </div>
      </div>
    </div>
  )
}

export default Form
