import React, { useState, useRef, useEffect } from "react"
import * as Styles from "./index.module.scss"
import { useLanguage } from "@hooks"
import { Cross } from "@dom"
import { css } from "@jsUtils"
import Headline from "../../components/headline"

const Slider = (props) => {
  const { data, onAnswer, active, setBackground, onPrevious } = props
  const isSpanish = useLanguage("es")
  const title = isSpanish ? data.title.headlineEs : data.title.headlineEn
  const copy = isSpanish ? data.title.titleEs : data.title.titleEn
  const min = data.min ? data.min : 0

  const [answer, setAnswer] = useState(null)
  const [width, setWidth] = useState(0)

  const sliderRef = useRef()

  useEffect(() => {
    if (answer) onAnswer(data.title.titleEn, answer)
  }, [answer])

  useEffect(() => {
    if (active) {
      // console.log("ACTIVE")
      onAnswer(data.title.titleEn, answer)
    }
  }, [active])

  const sliderVal = ((answer - min) / (data.max - min)) * 100
  useEffect(() => {
    setWidth(sliderRef.current.getBoundingClientRect().width - 100)
  }, [])
  return (
    <div className={Styles.component}>
      <div className={Styles.content}>
        <Headline copy={copy} title={title} onPrevious={onPrevious}></Headline>

        <div className={Styles.activity}>
          <div className={Styles.answer}>
            <Cross position={"top left"} />
            <Cross position={"top right"} />
            <Cross position={"bottom left"} />
            <Cross position={"bottom right"} />
            <div className={Styles.number}>{answer ? answer : min}</div>
          </div>
          <div className={Styles.sliderWrapper}>
            <div
              className={Styles.background}
              style={{
                background: `linear-gradient(to right, #d8b97d 0%, #d8b97d ${sliderVal}%, #DEE2E6 ${sliderVal}%, #DEE2E6 100%)`,
              }}
            ></div>
            <input
              ref={sliderRef}
              type="range"
              min={min}
              max={data.max}
              value={answer ? answer : min}
              className={Styles.slider}
              onChange={(e) => {
                setAnswer(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider
