import React from "react"
import * as Styles from "./index.module.scss"

import { css } from "@jsUtils"

const Button = (props) => {
  return (
    <div
      className={css(Styles.component, Styles[props.theme], props.className)}
      onClick={props.onClick}
    >
      {props.title}
    </div>
  )
}

export default Button
