// extracted by mini-css-extract-plugin
export var component = "index-module--component--3ATed";
export var wrapper = "index-module--wrapper--2sd39";
export var copy = "index-module--copy--1cA3L";
export var headline = "index-module--headline--2vrsw";
export var content = "index-module--content--1H1qQ";
export var cross = "index-module--cross--2mAxW";
export var top = "index-module--top--1N3Dr";
export var right = "index-module--right--2qDU5";
export var bottom = "index-module--bottom--25s__";
export var left = "index-module--left--3QHSs";