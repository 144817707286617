import React, { useContext, useEffect, useState } from "react"
import * as Styles from "./index.module.scss"
import { css } from "@jsUtils"

import Intro from "./screens/intro"
import Terms from "./screens/terms"
import Form from "./screens/form"
import Submit from "./screens/submit"

import MultipleMultipleChoice from "./screens/multipleMultipleChoice"
import MultipleChoice from "./screens/multipleChoice"
import Slider from "./screens/slider"
import AorB from "./screens/AorB"
import AorBAlt from "./screens/AorBAlt"

import { SiteContext } from "@context/siteContext"
import { PredictiveGamingContext } from "@context/predictiveGamingContext"
import Button from "../predictiveGaming/components/button"

const PredictiveGaming = ({ gameData }) => {
  const { actions: siteActions } = useContext(SiteContext)
  const { actions, answers } = useContext(PredictiveGamingContext)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [currentAnswer, setCurrentAnswer] = useState("")
  const onStart = () => {
    setCurrentQuestion(1)
  }
  const onProgress = () => {
    let next = currentQuestion + 1
    setCurrentQuestion(next)
  }

  const onAgree = () => {
    onProgress()
  }
  const onReturn = () => {
    let next = currentQuestion - 1
    setCurrentQuestion(next)
  }
  const onSubmit = (data) => {
    actions.onSubmit(data)
    onProgress()
  }

  const setBackground = (background) => {
    siteActions.setBackground(background)
  }

  useEffect(() => {
    if (currentQuestion > 0 && currentQuestion < answers.length - 1) {
      setCurrentAnswer(answers[currentQuestion - 1].answer + "")
    }
  }, [currentQuestion, answers])

  const resolveModule = (game, index) => {
    switch (game.fieldGroupName) {
      case "MultipleMultipleChoice":
        return (
          <MultipleMultipleChoice
            active={index + 1 == currentQuestion}
            data={game}
            onAnswer={actions.onAnswer}
            key={index}
            setBackground={setBackground}
            onPrevious={onReturn}
          />
        )
        break
      case "page_Playpredictorcontent_Questions_Multiplechoice":
        return (
          <MultipleChoice
            active={index + 1 == currentQuestion}
            data={game}
            onAnswer={actions.onAnswer}
            key={index}
            setBackground={setBackground}
            onPrevious={onReturn}
          />
        )
        break
      case "page_Playpredictorcontent_Questions_Slider":
        return (
          <Slider
            active={index + 1 == currentQuestion}
            data={game}
            onAnswer={actions.onAnswer}
            key={index}
            setBackground={setBackground}
            onPrevious={onReturn}
          />
        )
        break
      case "page_Playpredictorcontent_Questions_Aorb":
        return (
          <AorB
            active={index + 1 == currentQuestion}
            data={game}
            onAnswer={actions.onAnswer}
            key={index}
            setBackground={setBackground}
            onPrevious={onReturn}
          />
        )
        break
      case "page_Playpredictorcontent_Questions_Aorbalt":
        return (
          <AorBAlt
            active={index + 1 == currentQuestion}
            data={game}
            onAnswer={actions.onAnswer}
            key={index}
            setBackground={setBackground}
            onPrevious={onReturn}
          />
        )
        break
    }
  }
  return (
    <div className={Styles.root}>
      <div
        className={Styles.gameSlides}
        style={{
          transform: `translateY(-${currentQuestion * 100}vh)`,
        }}
      >
        <Intro
          onStart={onStart}
          active={currentQuestion == 0}
          setBackground={setBackground}
          nextGame={gameData.nextGame}
        ></Intro>
        {gameData.questions.map((game, index) => resolveModule(game, index))}
        <Terms
          onAgree={onAgree}
          onReturn={onReturn}
          active={currentQuestion == gameData.questions.length + 1}
          setBackground={setBackground}
        ></Terms>
        <Form
          onSubmit={onSubmit}
          active={currentQuestion == gameData.questions.length + 2}
          setBackground={setBackground}
        ></Form>
        <Submit
          active={currentQuestion == gameData.questions.length + 3}
          setBackground={setBackground}
          nextGame={gameData.nextGame}
        ></Submit>
      </div>

      <Button
        title={"Continue"}
        className={css(
          Styles.continueButton,
          currentQuestion > 0 && currentQuestion < gameData.questions.length + 1 && Styles.active,
          currentAnswer == "" && Styles.disabled,
        )}
        onClick={() => {
          if (currentAnswer != "") onProgress()
        }}
        theme={"white"}
      />
    </div>
  )
}

export default PredictiveGaming
