import React, { useEffect, useState } from "react"
import * as Styles from "./index.module.scss"
import { useLanguage } from "@hooks"
import { Cross } from "@dom"
import { css } from "@jsUtils"
import Headline from "../../components/headline"
import Choice from "../../components/choice"

const AorB = (props) => {
  const { data, onAnswer, active, setBackground, onPrevious } = props
  const isSpanish = useLanguage("es")
  const title = isSpanish ? data.title.headlineEs : data.title.headlineEn
  const copy = isSpanish ? data.title.titleEs : data.title.titleEn

  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (selected) onAnswer(data.title.titleEn, selected)
  }, [selected])

  useEffect(() => {
    if (active) setBackground("black")
  }, [active])

  const renderChoice = (choice, index) => {
    return (
      <Choice
        key={index}
        text={isSpanish ? choice.titleEs : choice.titleEn}
        value={choice.titleEn}
        selected={selected}
        setSelected={setSelected}
        className={Styles.choice}
      />
    )
  }

  return (
    <div className={Styles.component}>
      <div className={Styles.content}>
        <Headline copy={copy} title={title} onPrevious={onPrevious}></Headline>
        <div className={Styles.activity}>
          {data.content.map((choice, index) => (
            <div
              className={Styles.option}
              key={index}
              onClick={() => setSelected(isSpanish ? choice.titleEs : choice.titleEn)}
            >
              <Cross position={"top left"} theme={"white"} className={Styles.cross} />
              <Cross position={"top right"} theme={"white"} className={Styles.cross} />
              <Cross position={"bottom left"} theme={"white"} className={Styles.cross} />
              <Cross position={"bottom right"} theme={"white"} className={Styles.cross} />

              {choice.image && (
                <div
                  className={Styles.image}
                  style={{
                    backgroundImage: `url(${choice.image.mediaItemUrl})`,
                  }}
                ></div>
              )}
              <div className={css(Styles.element, selected == choice.titleEn && Styles.active)}>
                {choice.element}
              </div>
              {renderChoice(choice, index)}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AorB
