// extracted by mini-css-extract-plugin
export var component = "index-module--component--3eCgR";
export var wrapper = "index-module--wrapper--2qZdX";
export var copy = "index-module--copy--3Hlum";
export var headline = "index-module--headline--3f1RH";
export var content = "index-module--content--2VPN5";
export var cross = "index-module--cross--3cT2C";
export var top = "index-module--top--1dxIQ";
export var right = "index-module--right--1Ph2g";
export var bottom = "index-module--bottom--1weif";
export var left = "index-module--left--2H4Rw";