import React, { useState, useEffect } from "react"
import * as Styles from "./index.module.scss"
import { useLanguage } from "@hooks"
import { Cross } from "@dom"
import { css } from "@jsUtils"
import Headline from "../../components/headline"
import Choice from "../../components/choice"

const AorBAlt = (props) => {
  const { data, onAnswer, active, setBackground, onPrevious } = props
  const isSpanish = useLanguage("es")
  const title = isSpanish ? data.title.headlineEs : data.title.headlineEn
  const copy = isSpanish ? data.title.titleEs : data.title.titleEn

  const [selected, setSelected] = useState(null)

  useEffect(() => {
    if (active) setBackground("black")
  }, [active])

  useEffect(() => {
    if (selected) onAnswer(data.title.titleEn, selected)
  }, [selected])
  return (
    <div className={Styles.component}>
      {data.background && (
        <div
          className={Styles.background}
          style={{
            background: `url(${data.background.mediaItemUrl})`,
          }}
        />
      )}
      <div className={Styles.content}>
        <Headline copy={copy} title={title} onPrevious={onPrevious}></Headline>
        <div className={Styles.activity}>
          {data.content.map((choice, index) => (
            <div
              key={index}
              className={css(Styles.option, selected == choice.titleEn && Styles.active)}
              onClick={() => setSelected(choice.titleEn)}
            >
              <Cross position={"top left"} theme={"white"} className={Styles.cross} />
              <Cross position={"top right"} theme={"white"} className={Styles.cross} />
              <Cross position={"bottom left"} theme={"white"} className={Styles.cross} />
              <Cross position={"bottom right"} theme={"white"} className={Styles.cross} />

              <div className={Styles.answer}>{isSpanish ? choice.titleEs : choice.titleEn}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AorBAlt
